import {apiMap, getEnvironmentTag} from "@autopay.io/common/lib/util";
import {ApiDocs} from "../types/apiDoc";

const defaultInternalPath = "/api-doc/internal.json"
const defaultPublicPath = "/public-api/api-doc/public.json"
const defaultBetaPath = "/public-api/api-doc/beta.json"

export const apiDocPaths: ApiDocs = [
    {
        name: "Autopay-server",
        url: "autopay-server",
        internalPaths: ["https://parking.qa.autopay.io:45557/v1/swagger.json"]
    },
    {
        name: "Users",
        url: "users",
        internalPaths: [apiDocPath(apiMap.users, defaultInternalPath)],
        betaPaths: [
            apiDocPath(apiMap.users, defaultBetaPath),
            apiDocPath(apiMap.payments, defaultBetaPath),
            apiDocPath(apiMap.permits, defaultBetaPath),
            apiDocPath(apiMap.sessions, defaultBetaPath),
            apiDocPath(apiMap.receipt, defaultBetaPath),
        ],
    },
    {
        name: "Permits",
        url: "permits",
        internalPaths: [apiDocPath(apiMap.permits, defaultInternalPath)],
    },
    {
        name: "Payments",
        url: "payments",
        internalPaths: [apiDocPath(apiMap.payments, defaultInternalPath)],
    },
    {
        name: "Sessions",
        url: "sessions",
        internalPaths: [apiDocPath(apiMap.sessions, defaultInternalPath)],
    },
    {
        name: "External profiles",
        url: "external-profiles",
        internalPaths: [apiDocPath(apiMap["external-profiles"], defaultInternalPath)],
    },
    {
        name: "Authorization",
        url: "authorization",
        internalPaths: [apiDocPath(apiMap.authorization, defaultInternalPath)],
    },
    {
        name: "Organizations",
        url: "organizations",
        internalPaths: [apiDocPath(apiMap.organizations, defaultInternalPath)],
    },
    {
        name: "Search",
        url: "search",
        internalPaths: [apiDocPath(apiMap.search, defaultInternalPath)],
    },
    {
        name: "Notifications",
        url: "notifications",
        internalPaths: [apiDocPath(apiMap.notifications, defaultInternalPath)],
    },
    {
        name: "Analytics",
        url: "analytics",
        internalPaths: [apiDocPath(apiMap.analytics, defaultInternalPath)],
    },
    {
        name: "Notification center",
        url: "notification-center",
        internalPaths: [apiDocPath(apiMap.notificationCenter, defaultInternalPath)],
    },
    // core
    {
        name: "Plate corrections",
        url: "plate-corrections",
        internalPaths: [apiDocPath(apiMap.plateCorrections, defaultInternalPath)],
    },
    {
        name: "hardware",
        url: "hardware",
        internalPaths: [apiDocPath(apiMap.hardware, defaultInternalPath)],
    },
    {
        name: "Invoicing",
        url: "invoicing",
        internalPaths: [apiDocPath(apiMap.invoicing, defaultInternalPath)],
    },
    {
        name: "Price calculator",
        url: "price-calculator",
        internalPaths: [apiDocPath(apiMap.priceCalculator, defaultInternalPath)],
    },
    {
        name: "Vehicle",
        url: "vehicle",
        internalPaths: [apiDocPath(apiMap.vehicle, defaultInternalPath)],
    },
    {
        name: "Receipt",
        url: "receipt",
        internalPaths: [apiDocPath(apiMap.receipt, defaultInternalPath)],
    },
    {
        name: "Locations",
        url: "locations",
        internalPaths: [apiDocPath(apiMap.locations, defaultInternalPath)],
    },
    {
        name: "Manual sessions",
        url: "manual-sessions",
        internalPaths: [apiDocPath(apiMap.manualSessions, defaultInternalPath)],
    },
    {
        name: "Onstreet parking",
        url: "onstreet-parking",
        betaPaths: [
            apiDocPath(apiMap.manualSessions, defaultBetaPath),
            apiDocPath(apiMap.sessions, defaultBetaPath)
        ],
    },
    // API
    {
        name: "Status",
        url: "status",
        publicPaths: [apiDocPath(apiMap.status, defaultPublicPath)]
    },    {
        name: "Accounting",
        url: "accounting",
        internalPaths: [apiDocPath(apiMap.accounting, defaultInternalPath)],
        publicPaths: [apiDocPath(apiMap.accounting, defaultPublicPath)]
    },
    {
        name: "Kiosk",
        url: "kiosk",
        internalPaths: [apiDocPath(`https://api${getEnvironmentTag()}.autopay.io/kiosk`, defaultInternalPath)],
        betaPaths: [apiDocPath(`https://api${getEnvironmentTag()}.autopay.io/kiosk`, defaultBetaPath)]
    },

];

/**
 * Generate api doc path from a host and a path. Strips extra slashes from both host and path.
 *
 * @param host Base host (e.g. "https://selfservice.test.autopay.io/users")
 * @param path API doc path (e.g. "/public-api/api-doc/public.json")
 */
function apiDocPath(host: string, path: string) {
    return `${host.replace(/\/$/, "")}/${path.replace(/^\//, "")}`
}
