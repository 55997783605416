import {ApiDocs, SelectedApiDoc} from "../types/apiDoc"
import {apiDocPaths} from "../data/apiDocPaths";

const toArray = (input: string | string[]): string[] =>
    typeof input === 'string' ? [input] : input

const filterInternalApiDocs = (defaultPaths: ApiDocs): SelectedApiDoc[] => (
    defaultPaths
        .flatMap(base => (base.internalPaths !== undefined ? [{
            name: base.name,
            url: base.url,
            paths: toArray(base.internalPaths)
        }] : []))
)

const filterPublicApiDocs = (defaultPaths: ApiDocs): SelectedApiDoc[] => (
    defaultPaths
        .flatMap(base => (base.publicPaths !== undefined ? [{
            name: base.name,
            url: base.url,
            paths: toArray(base.publicPaths)
        }] : []))
)

const filterBetaApiDocs = (defaultPaths: ApiDocs): SelectedApiDoc[] => (
    defaultPaths
        .flatMap(base => (base.betaPaths !== undefined ? [{
            name: base.name,
            url: base.url,
            paths: toArray(base.betaPaths)
        }] : [])))

export const internalApiDocs = filterInternalApiDocs(apiDocPaths)
export const publicApiDocs = filterPublicApiDocs(apiDocPaths)
export const betaApiDocs = filterBetaApiDocs(apiDocPaths)
