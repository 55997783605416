import {Navigate, Route, Routes} from "react-router";
import {BrowserRouter} from "react-router-dom";
import * as React from "react";
import ApiDocApp from "./app";
import {initializeToasts, ToastContextProvider, ToastElements} from "@autopay.io/style";
import {betaApiDocs, internalApiDocs, publicApiDocs} from "./util/apiDocPathUtil";

const ApiDocRouter = () => {
    const {toasts, addNewToast, removeToast} = initializeToasts()

    return (
        <ToastContextProvider value={addNewToast}>
            <ToastElements toasts={toasts} removeToast={removeToast} />
            <BrowserRouter >
                <Routes>
                    <Route path="/">
                        <Route path="internal">
                            <Route path=":api" element={<ApiDocApp apiDocs={internalApiDocs} />} />
                            <Route index element={<Navigate replace to={`${internalApiDocs[0].url}`} />} />
                        </Route>
                        <Route path="public-api">
                            <Route path="beta">
                                <Route path=":api" element={<ApiDocApp apiDocs={betaApiDocs} />} />
                                <Route index element={<Navigate replace to={`${betaApiDocs[0].url}`} />} />
                            </Route>
                            <Route path="public">
                                <Route path=":api" element={<ApiDocApp apiDocs={publicApiDocs} />} />
                                <Route index element={<Navigate replace to={`${publicApiDocs[0].url}`} />} />
                            </Route>
                            <Route index element={<Navigate replace to="public" />} />
                        </Route>
                        <Route index element={<Navigate replace to="/public-api" />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </ToastContextProvider>
    )
}

export default ApiDocRouter
