import * as React from "react";
import {useEffect, useState} from "react";
import SwaggerUI from "swagger-ui-react";
import {AioPage, Select, Spinner, useToasts} from "@autopay.io/style";
import {AioHeaderOptions} from "@autopay.io/style/lib/components/Layout/AioPageTypes";
import "swagger-ui-react/swagger-ui.css";
import "@autopay.io/style/autopay-io.css";
import merge from "ts-deepmerge";
import {SelectedApiDoc} from "./types/apiDoc";
import {resolvePath, useMatch, useParams, useResolvedPath} from "react-router";
import {useNavigate} from "react-router-dom";

interface ApiDocAppProps {
    apiDocs: SelectedApiDoc[],
}

const ApiDocApp = ({apiDocs}: ApiDocAppProps) => {
    const { api } = useParams();
    const navigate = useNavigate();
    const [spec, setSpec] = useState<object | undefined>(undefined);
    const [url, setUrl] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const selectedApiDocPaths = apiDocs.findIndex((doc) => doc.url === api);

    const {addToast} = useToasts();

    useEffect(() => {
        setLoading(true)
        if (apiDocs.length > 0 && selectedApiDocPaths !== -1) {
            if (apiDocs[selectedApiDocPaths].paths.length === 1) {
                setUrl(apiDocs[selectedApiDocPaths].paths[0])
                setSpec(undefined);
                setLoading(false);    
            } else {
                mergeSpecs(apiDocs[selectedApiDocPaths].paths).then(spec => {
                    setUrl(undefined);
                    setSpec(spec);
                    setLoading(false);
                });
            }
        } else {
            setUrl(undefined)
            setSpec(undefined);
            setLoading(false);
        }
    }, [api])

    const header: AioHeaderOptions = {
        style: "orange",
        right: [
            {element: selectApi()}
        ]
    };

    return <AioPage type="page-with-header" header={header}>
        {loading && <Spinner size="l" delay="none" />}
        {!loading && (spec || url) && <SwaggerUI spec={spec} url={url} />}
    </AioPage>

    function selectApi() {
        return <Select
            value={api}
            options={apiDocs.map((selectedApiDoc, index) => (
                {value: selectedApiDoc.url, label: selectedApiDoc.name }
            ))}
            onChange={(event => {
                navigate(`../${event.target.value}`);
            })}
        />
    }

    async function mergeSpecs(paths: string[]): Promise<object> {
        const specs = await Promise.allSettled(
            paths.map(async (path) => {
                const response = await fetch(path)
                return await response.json() as Promise<object>
            })
        )
        const fulfilledSpecs = specs
            .flatMap((result) => {
                if (result.status === 'fulfilled') {
                    return [result.value]
                } else {
                    return []
                }
            })

        specs.filter(promiseResult => promiseResult.status === "rejected")
            .forEach(promiseResult => {
                addToast({
                    type: "error",
                    body: (promiseResult as PromiseRejectedResult).reason.toString(),
                    autoDismiss: true,
                })
            })

        if (fulfilledSpecs.length > 0) {
            return merge(...fulfilledSpecs)
        } else {
            return {};
        }
    }
}

export default ApiDocApp